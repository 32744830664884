/*
	 Form Block
	 ========================================================================== */

.FormBlock {
    .FormBlock__copy {
        ul {
            margin-left: 1.2em;
            @apply list-disc;
        }

        a {
            @apply font-bold text-primary-50 hover:text-primary-100 transition;
        }
    }
}