@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

  a {
    text-decoration: none !important;
  }
}

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}