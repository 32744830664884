/* ==========================================================================
	 Navigation
	 ========================================================================== */

.Navigation {
    .Navigation__nav {


    }

    .Navigation__mobilenav {

    }

    .Navigation__mobilesearch {

    }

    .navbar-brand {




    }
}

#ithotdesknav:not( .mm-menu ) { display: none; }


.mm-menu, .mm-panel, .mm-navbar, {
    @include below(break(l)) {
        z-index: 100000;
        background-color: color(primary-900) !important;
        .mm-listitem a, .mm-navbar__title, .mm-navbar a {
            color: color(white) !important;
        }
        .mm-btn:after, .mm-navbar__btn:before {
            border-color: color(white);
        }
    }
}
